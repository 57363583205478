@font-face {
    font-family: 'Avenir';
    src: url('https://assets.docta.nl/79c4a6763cd37a08c07c061494eb890d6703197796f124ed66842cc73dedb5ed/avenir.otf');
    descent-override: 25%;
}

@font-face {
    font-family: 'Baloo Tamuddu';
    src: url('https://assets.docta.nl/d0a7ffc8fe3b97db7d741b45e9a92a511a0d9cad53fff0dfa096c79d12b625af/balootammudu.ttf');
    descent-override: 50%;
}

body {
    margin: 0;
    font-family: Avenir, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
